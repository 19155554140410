<template>
  <div>
    <section class="course-cateory-section programs-offer">
      <div class="container">
        <div class="category-cntr" v-if="selectedCourseInfo">
          <breadcrumb v-on:onUpdateWizard="updateWizard" :model="selectedCourseInfo"></breadcrumb>
          <component v-bind:is="currentWizard" v-on:onUpdateWizard="updateWizard"></component>
          <div class="circle-pattern">
            <img src="/assets/images/banner/circle-pattern.svg " alt="" />
          </div>
        </div>
      </div>
      <div class="circle-pattern-2">
        <img src="/assets/images/banner/circle-pattern-2.svg " alt="" />
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { course as courseStoreKeys } from "../../../store/keys";
import breadcrumb from "../breadcrumb";
export default {
  metaInfo() {
    return {
      title: `${this.selectedCourseInfo.program.name} | KIPS VIRTUAL`,
      titleTemplate: "",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    };
  },
  components: {
    breadcrumb,
    "wizard-step2": () => import("./program"),
    "wizard-step3": () => import("./wizard-step-3"),
  },
  data() {
    return {
      programName: "",
    };
  },
  asyncData({ store, route }) {
    return Promise.resolve();
  },
  watch: {
    $route(to, from) {
      //alert(to + "load" + from);
      //this.page = window.location.hash.split("#/")[1];

      this.updateWizard("wizard-step2");
    },
  },
  mounted() {
    this.updateWizard("wizard-step2");
  },
  beforeDestroy() {
    this.reset_SelectedCourseInfo();
  },
  computed: {
    ...mapGetters(courseStoreKeys.namespace, courseStoreKeys.getters),
  },
  methods: {
    ...mapActions(courseStoreKeys.namespace, courseStoreKeys.actions),
    activeTabClass(activeTabComponent) {
      return activeTabComponent === this.currentWizard ? "active-btn btn" : "btn";
    },
    updateWizard(wizard) {
      this.update_CurrentWizard({
        data: wizard,
      }).then((response) => {});
    },
  },
};
</script>

<style></style>
